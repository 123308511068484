/* eslint-disable */
import React, { useRef, useEffect, useState } from "react";
import { Map, TileLayer } from "react-leaflet";
import L from "leaflet";
import ArtData from "./assests/data/artdata.json";
import palette from "./assests/img/painting.png";
import CloseSvg from "./assests/img/close.svg";

const App = () => {
  const mapRef = useRef();
  const [OpenPopup, setOpenPopup] = useState(false);
  const [DataMarker, setDataMarker] = useState({
    img: 1,
    title: "Theresa Sacket",
    link: "",
    name: "Outside The Box -  Curiosity",
    location: "401 E. Grand Ave.",
  });
  const [bounds, setbounds] = useState([
    [-90, -180],
    [90, 180],
  ]);

  const artIcon = new L.Icon({
    iconUrl: palette,
    iconSize: [25, 25],
  });
  useEffect(() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    var groupLayer = new L.FeatureGroup();
    ArtData.map((o) => {
      L.marker([o.lat, o.lng], { icon: artIcon })
        .addTo(groupLayer)
        .on("click", function (e) {
          setDataMarker({
            img: o.img,
            title: o.title,
            name: o.name,
            location: o.address,
            link: o.link,
          });
          setOpenPopup(true);
        });
    });
    groupLayer.addTo(map);
    // setbounds(groupLayer.getBounds());
    map.fitBounds(groupLayer.getBounds());
  }, []);

  return (
    <>
      <div className="popup-container">
        <div
          className={
            OpenPopup ? "popup-container-child-active" : "popup-container-child"
          }
        >
          <div
            className="popup-container-child-close-box"
            onClick={() => setOpenPopup(false)}
          >
            <img
              src={CloseSvg}
              className="popup-container-child-close media-icon"
            />
          </div>
          <div className="popup-container-child-img-box">
            <img
              src={require(`./assests/img/(${DataMarker.img}).jpg`).default}
              className="popup-container-child-img"
            />
          </div>

          <div className="popup-container-child-box">
            <div className="popup-container-child-title">
              {DataMarker.title}
            </div>

            <div className="popup-container-child-name media-icon">
              <a
                style={{ textDecoration: "inherit", color: "inherit" }}
                href={DataMarker.link}
              >
                {DataMarker.name}
              </a>
            </div>
            <div className="popup-container-child-location">
              {DataMarker.location}
            </div>
          </div>
        </div>
      </div>

      <Map
        ref={mapRef}
        center={[0, 0]}
        zoom={3}
        maxZoom={19}
        minZoom={2}
        bounceAtZoomLimits={true}
        maxBoundsViscosity={0.95}
        maxBounds={bounds}
      >
        <TileLayer
          noWrap={false}
          url="https://{s}.tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token={accessToken}"
          accessToken="Q6TlPD96qItYafHxj0s1kyNNU0DsHrLO95GdtHi64FISFxXecpHBAVTJ2KAOxm6b"
        />
      </Map>
    </>
  );
};

export default App;
